.PeriodField__RadioType {
  :global(.label) {
    padding-top: 8px;
  }

  .radiogroup {
    @apply flex items-center gap-x-4;
    height: 40px;
  }
}

.PeriodField_CustomPeriod {
  @apply flex items-center gap-x-2;
}
