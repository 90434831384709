.IncludedKeywordField {
  @apply flex items-start;
  :global(.label) {
    padding-top: 8px;
  }

  .row {
    @apply flex items-center gap-x-3;
    height: 40px;
  }
}
